import React from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "gatsby"
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import FormImg from "../images/form-img.png";

const FormPage = () => {
    return (
        <>
            <Header />
                <section className="form-page-wrapper">
                    <Container>
                        <Row>
                            <Col lg={5} className="order-lg-1">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="form-page-details">
                                        <div className="form-page-heading">
                                            <h1>{"Contact David Astburys"}</h1>
                                            <p>{"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sit amet ligula non tortor pulvinar dictum ut et dignissim dolor."}</p>
                                        </div>

                                        <div className="form-page-form-details">
                                            <Form>
                                                <Form.Group className="form-field-group">
                                                    <Form.Label>What does your enquiry relate to?</Form.Label>
                                                    <div className="d-md-flex justify-content-between form-page-radio">
                                                        <Form.Check
                                                            type={"radio"}
                                                            label={`General`}
                                                            id={`general`}
                                                            name="enquiry"
                                                            className="form-radio"
                                                        />
                                                        <Form.Check
                                                            type={"radio"}
                                                            label={`Valuation`}
                                                            id={`valuation`}
                                                            name="enquiry"
                                                            className="form-radio"
                                                        />
                                                        <Form.Check
                                                            type={"radio"}
                                                            label={`Other`}
                                                            id={`other`}
                                                            name="enquiry"
                                                            className="form-radio"
                                                        />
                                                    </div>
                                                </Form.Group>
                                                
                                                <Form.Group className="form-field-group">
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter Name" />
                                                </Form.Group>

                                                <Form.Group className="form-field-group">
                                                    <Form.Label>Email Address</Form.Label>
                                                    <Form.Control type="email" placeholder="Enter Email" />
                                                </Form.Group>

                                                <Form.Group className="form-field-group">
                                                    <Form.Label>Telephone</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter Telephone number" />
                                                </Form.Group>

                                                <Form.Group className="form-field-group" controlId="">
                                                    <Form.Label>Message</Form.Label>
                                                    <Form.Control as="textarea" placeholder="Type your message here..." rows={5} />
                                                </Form.Group>

                                                <Button variant="" className="btn-fill-primary w-100" type="submit">
                                                    Submit Enquiry
                                                </Button>

                                                <div className="contact-terms">By clicking Submit Enquiry, you agree to our <Link to="#">Terms</Link> and <Link to="#">Privacy Policy.</Link></div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={1} className="order-lg-2"></Col>
                            <Col lg={6} className="order-lg-3 d-lg-block d-none">
                                <div className="form-banner">
                                    <img src={FormImg} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            <Footer />
        </>
    )
}

export default FormPage